document.addEventListener('turbo:load', function () {
  Give.setupAlertDismissal()
});

window.Give.setupAlertDismissal = function () {
  let dismissers = document.querySelectorAll('.give-toast')
  dismissers.forEach(function (el) {
    el.addEventListener('click', function (e) { Give.dismissAlert(e.target) })
    setTimeout(function () { Give.dismissAlert(el) }, 3000)
  })
};

window.Give.dismissAlert = function (el) {
  var alert = el.closest('.give-toast');
  alert?.classList?.add('anim-fade-out')

  // Make sure this matches the animation speed.
  setTimeout(function () {
    alert?.remove()
  }, 500)
};

window.Give.showAlert = function (alertText, type) {
  const toastTemplate = `<div class="give-toast  give-toast--alert alert-${type}">
    <div class="give-toast__text">
      ${alertText}
    </div>
  </div>`

  var template = document.createElement('template')
  var html = toastTemplate.trim()
  template.innerHTML = html
  document.body.appendChild(template.content.firstChild)

  Give.setupAlertDismissal()
}