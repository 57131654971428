const countries = {
  "Canada": [
    ["Alberta", "AB"],
    ["British Columbia", "BC"],
    ["Manitoba", "MB"],
    ["New Brunswick", "NB"],
    ["Newfoundland and Labrador", "NL"],
    ["Northwest Territories", "NT"],
    ["Nova Scotia", "NS"],
    ["Nunavut", "NU"],
    ["Ontario", "ON"],
    ["Prince Edward Island", "PE"],
    ["Québec", "PQ"],
    ["Saskatchewan", "SK"],
    ["Yukon", "YT"]
  ],
  "United States": [
    ["Alabama", "AL"],
    ["Alaska", "AK"],
    ["Arizona", "AZ"],
    ["Arkansas", "AR"],
    ["California", "CA"],
    ["Colorado", "CO"],
    ["Connecticut", "CT"],
    ["Delaware", "DE"],
    ["District of Columbia", "DC"],
    ["Florida", "FL"],
    ["Georgia", "GA"],
    ["Hawaii", "HI"],
    ["Idaho", "ID"],
    ["Illinois", "IL"],
    ["Indiana", "IN"],
    ["Iowa", "IA"],
    ["Kansas", "KS"],
    ["Kentucky", "KY"],
    ["Louisiana", "LA"],
    ["Maine", "ME"],
    ["Maryland", "MD"],
    ["Massachusetts", "MA"],
    ["Michigan", "MI"],
    ["Minnesota", "MN"],
    ["Mississippi", "MS"],
    ["Missouri", "MO"],
    ["Montana", "MT"],
    ["Nebraska", "NE"],
    ["Nevada", "NV"],
    ["New Hampshire", "NH"],
    ["New Jersey", "NJ"],
    ["New Mexico", "NM"],
    ["New York", "NY"],
    ["North Carolina", "NC"],
    ["North Dakota", "ND"],
    ["Ohio", "OH"],
    ["Oklahoma", "OK"],
    ["Oregon", "OR"],
    ["Pennsylvania", "PA"],
    ["Puerto Rico", "PR"],
    ["Rhode Island", "RI"],
    ["South Carolina", "SC"],
    ["South Dakota", "SD"],
    ["Tennessee", "TN"],
    ["Texas", "TX"],
    ["Utah", "UT"],
    ["Vermont", "VT"],
    ["Virginia", "VA"],
    ["Washington", "WA"],
    ["West Virginia", "WV"],
    ["Wisconsin", "WI"],
    ["Wyoming", "WY"]
  ]
}

const updateRegionSelect = function (canadian, american) {
  var countrySelect = document.querySelector("#countries") as HTMLSelectElement
  var postal_code = document.querySelector("#postal_code") as HTMLInputElement
  var regionSelect = document.querySelector("#regions") as HTMLSelectElement

  var selectedCountry = countrySelect.selectedOptions
  var first = selectedCountry[0]

  regionSelect.removeAttribute("disabled")

  if (first && first.label === "Canada") {
    regionSelect.style.display = "block"
    regionSelect.innerHTML = canadian
    postal_code.placeholder = "Postal code"

  } else if (first && first.label === "United States") {
    regionSelect.innerHTML = american
    regionSelect.style.display = "block"
    postal_code.placeholder = "ZIP"
  } else {
    regionSelect.setAttribute("disabled", "")
    regionSelect.innerHTML = ""
    regionSelect.style.display = "none"

    postal_code.placeholder = "Postal / ZIP"
  }
}

$(function () {
  var regionSelect = document.querySelector("#regions") as HTMLSelectElement
  if (!regionSelect) return

  regionSelect.removeAttribute("disabled")

  var canadian
  var american

  const initialCountry = (document.querySelector("#countries") as HTMLSelectElement).selectedOptions[0]

  if (initialCountry.label === "Canada") {
    canadian = document.querySelector("#regions").innerHTML
    american = countries["United States"].map((state) => `<option value="${state[1]}">${state[0]}</option>`).join()
  } else {
    canadian = countries["Canada"].map((province) => `<option value="${province[1]}">${province[0]}</option>`).join()
    american = document.querySelector("#regions").innerHTML
  }

  $(document).on("change", "#countries", function () {
    console.log("Countries changed!")
    updateRegionSelect(canadian, american)
  })

  $(document).on("turbo:load", function () {
    if (document.querySelector("#countries")) {
      updateRegionSelect(canadian, american)
    }
  })

  if (document.querySelector("#countries")) {
    updateRegionSelect(canadian, american)
  }
})