const parseNumberInput = (value: string) => {
  if (value == '') {
    return 0
  } else {
    return parseInt(value.replace(/\D/g, ''), 10)
  }
}

const parsePercentInput = (value: string) => {
  if (value == '') {
    return 0
  } else {
    let num = parseInt(value.replace(/\D/g, ""), 10)
    if (isNaN(num)) num = 0
    return Math.trunc(num) / 100
  }
}

const formatPercent = (value: number) => {
  return `${Math.round(value * 100.0).toLocaleString()}%`
}

const parseCurrencyInput = (value: string) => {
  if (value == '') {
    return 0
  } else {
    return Math.trunc(parseInt(value.replace(/\D/g, ""), 10))
  }
}

const formatCurrency = (value: number) => {
  return `$${Math.round(value).toLocaleString()}`
}

function updateEstimate () {
  console.log("UpdateEstimate")

  const matchPercentageEl = document.querySelector("#matching_program_match_percent")
  const matchLimitEl = document.querySelector("#matching_program_per_donor_limit")
  const employeeCountEl = document.querySelector("#estimator_employee_count")
  const employeeParticipationEl = document.querySelector("#estimator_giving_percent")
  const employeeAmountEl = document.querySelector("#estimator_donation_amount")

  const matchPercent = parsePercentInput((matchPercentageEl as HTMLInputElement).value)
  const matchLimit = parseCurrencyInput((matchLimitEl as HTMLInputElement).value)
  const employeeCount = parseNumberInput((employeeCountEl as HTMLInputElement).value)
  const employeeParticipation = parsePercentInput((employeeParticipationEl as HTMLInputElement).value)
  const employeeAmount = parseCurrencyInput((employeeAmountEl as HTMLInputElement).value)

  const estimateEmployeeCountEl = document.querySelector("#estimate-employee-count") as HTMLSpanElement
  const estimateEmployeeCount = Math.floor(employeeCount * employeeParticipation)
  estimateEmployeeCountEl.innerText = estimateEmployeeCount.toLocaleString()

  const estimateAmountEl = document.querySelector("#estimate-amount-display") as HTMLSpanElement
  const individualAmount = Math.min(matchLimit, employeeAmount * matchPercent)
  estimateAmountEl.innerText = formatCurrency(Math.floor(individualAmount * estimateEmployeeCount))

  const maxEmployeeCountEl = document.querySelector("#max-employee-count") as HTMLSpanElement
  maxEmployeeCountEl.innerText = employeeCount.toLocaleString()

  const maxAmountEl = document.querySelector("#max-amount-display") as HTMLSpanElement
  maxAmountEl.innerText = formatCurrency(Math.floor(individualAmount * employeeCount))
}

document.addEventListener("turbo:load", () => {
  if (!document.querySelector(".match-estimate")) return

  const matchPercentageEl = document.querySelector("#matching_program_match_percent")
  const matchLimitEl = document.querySelector("#matching_program_per_donor_limit")
  const employeeCountEl = document.querySelector("#estimator_employee_count")
  const employeeParticipationEl = document.querySelector("#estimator_giving_percent")
  const employeeAmountEl = document.querySelector("#estimator_donation_amount")

  matchPercentageEl.addEventListener("change", (e) => {
    const el = e.target as HTMLInputElement
    const newValue = parsePercentInput(el.value)
    el.value = formatPercent(newValue)

    updateEstimate()
  })

  matchLimitEl.addEventListener("change", (e) => {
    const el = e.target as HTMLInputElement
    const newValue = parseCurrencyInput(el.value)
    el.value = formatCurrency(newValue)

    updateEstimate()
  })

  employeeCountEl.addEventListener("change", (e) => {
    const el = e.target as HTMLInputElement
    const newValue = parseNumberInput(el.value)
    el.value = newValue.toLocaleString()

    updateEstimate()
  })

  employeeParticipationEl.addEventListener("change", (e) => {
    const el = e.target as HTMLInputElement
    const newValue = parsePercentInput(el.value)
    el.value = formatPercent(newValue)

    updateEstimate()
  })

  employeeAmountEl.addEventListener("change", (e) => {
    const el = e.target as HTMLInputElement
    const newValue = parseCurrencyInput(el.value)
    el.value = formatCurrency(newValue)

    updateEstimate()
  })

  updateEstimate()
})