import "@hotwired/turbo-rails"

import Rails from "@rails/ujs"
Rails.start()

// Support component names relative to this directory:
var componentRequireContext = require.context("components/", true);
var ReactRailsUJS = require("react_ujs");
window.ReactRailsUJS = ReactRailsUJS
ReactRailsUJS.useContext(componentRequireContext);

// https://github.com/reactjs/react-rails/issues/1103
ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount);
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount);

// console.log('Found components:')
// console.log(componentRequireContext.keys())
// console.log('Loaded application pack...')

// START AXIOS
import axios from 'axios'
const csrfEl = document.querySelector("meta[name='csrf-token']")
// This check is being done because Rails does not output the
// CSRF token in test mode. This was causing the JS to error
// out when running system tests.
let csrf
if (csrfEl) {
  csrf = csrfEl.getAttribute("content")
}

const axiosInstance = axios.create({
  timeout: 5000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'X-CSRF-Token': csrf
  }
});

window.axios = axiosInstance
// END AXIOS

// AIRBNB DATE PICKER
import 'react-dates/lib/css/_datepicker.css';
//END AIRBNB

require("jquery")
require("@nathanvda/cocoon")

// Handles hiding and dismissing alerts
import './toasts'
require("trix")
require("@rails/actiontext")

$(document).on('turbo:load', function () {
  Rails.refreshCSRFTokens();
})

import "./react_rails_mutation_observer"
import "./region_country_dropdowns"
import "./match_estimation_form"
import "./play_lotties"

import "./react_loader"