import React from 'react'
import ReactDOM from 'react-dom'

declare const ReactRailsUJS

document.addEventListener("DOMContentLoaded", () => {
  const findComponents = (childNodes: NodeList, testFn: (n: Node) => Boolean, nodes: Node[] = []): Node[] => {
    for (let child of childNodes) {
      if (child.childNodes.length > 0 && !testFn(child)) {
        nodes = findComponents(child.childNodes, testFn, nodes)
      } else if (testFn(child)) {
        nodes = nodes.concat([child])
      }
    }

    return nodes
  }

  const mountComponents = (nodes: Node[]) => {
    for (let child of nodes) {
      const className = (child as Element).getAttribute(ReactRailsUJS.CLASS_NAME_ATTR)
      if (className) {
        if ((child as HTMLElement).dataset.reactRendered) continue

        // Taken from ReactRailsUJS as is.
        const constructor = ReactRailsUJS.getConstructor(className)
        const propsJson = (child as Element).getAttribute(ReactRailsUJS.PROPS_ATTR)
        const props = propsJson && JSON.parse(propsJson)

        // Improvement:
        // Was this component already rendered? Just hydrate it with the props coming in.
        // This is currently acceptable since all our components are expected to be reset
        // on page navigation.
        const component = React.createElement(constructor, props) as any
        ReactDOM.render(component, child as Element);
        (child as HTMLElement).dataset.reactRendered = 'true'
      }
    }
  }

  const callback = function (mutationsList: MutationRecord[], observer: MutationObserver) {
    const start = performance.now()
    // console.log("ReactRails: Mutation callback started...", mutationsList)

    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        if (mutation.addedNodes.length > 0) {
          const mountableNodes = findComponents(mutation.addedNodes, (child) => {
            return !!(child as HTMLElement).dataset?.reactClass
          })

          mountComponents(mountableNodes)
        }
      }
    }

    // console.log("ReactRails: Mutation callback complete.", performance.now() - start)
  };

  const observer = new MutationObserver(callback)
  // console.log("ReactRails: Start mutation observer...")
  observer.observe(document, { childList: true, subtree: true })
})
